body {
  font-family: "Open Sans" !important;
}

.border_filtra {
  border: 1px double #1079be;
  /* border : 1px double #8BC441; */
  background-color: #eaf1f8;
  border-radius: 20px;
}

.modal-header .btn-close {
  padding-right: 1.5rem !important;
}